$(document).ready(function () {

  ; (function ($, window, document, undefined) {
    'use strict';

    var elSelector = '#header',
      $element = $(elSelector);

    if (!$element.length) return true;

    var elHeight = 0,
      elTop = 0,
      $document = $(document),
      dHeight = 0,
      $window = $(window),
      wHeight = 0,
      wScrollCurrent = 0,
      wScrollBefore = 0,
      wScrollDiff = 0;

    $window.on('scroll', function () {
      elHeight = $element.outerHeight();
      dHeight = $document.height();
      wHeight = $window.height();
      wScrollCurrent = $window.scrollTop();
      wScrollDiff = wScrollBefore - wScrollCurrent;
      elTop = parseInt($element.css('top')) + wScrollDiff;

      if (wScrollCurrent <= 0) {
        $element.css('top', 0);
        $element.removeClass('nav-down', 400);
        // scrolled to the very top; element sticks to the top
      } else if (wScrollDiff > 0) {
        $element.addClass('nav-down');
        // scrolled up; element slides in
        $element.css('top', elTop > 0 ? 0 : elTop);
      }
      else if (wScrollDiff < 0) // scrolled down
      {
        if (wScrollCurrent + wHeight >= dHeight - elHeight) {
          $element.addClass('nav-down');
          // scrolled to the very bottom; element slides in
          $element.css('top', (elTop = wScrollCurrent + wHeight - dHeight) < 0 ? elTop : 0);
        } else {
          // scrolled down; element slides out
          $element.css('top', Math.abs(elTop) > elHeight ? -elHeight : elTop);
        }
      }

      wScrollBefore = wScrollCurrent;
    });

  })(jQuery, window, document);

  if (window.cookieconsent) {
    window.cookieconsent.initialise({
      "palette": {
        "popup": { "background": "#252e39" },
        "button": { "background": "#1a9bdb" }
      },
      "theme": "classic",
      "content": {
        "message": "This website uses only anonymous analytical cookies to understand how the website is being used.",
        "dismiss": "I agree",
        "link": "privacy statement",
        "href": "/cookie-beleid"
      }
    });
  }

  /* MOBILE HAMBURGER ICON ACTION */
  /*var hamburger = $('#hamburger-icon');
  hamburger.click(function () {
    hamburger.toggleClass('active');
    return false;
  });*/

  $('#hamburger-icon').click(function () {
    if ($(this).hasClass('active')) {
      $('#header-menu').removeClass('mobile-open');
    } else {
      $('#header-menu').addClass('mobile-open');
    }
    $(this).toggleClass('active');
  });

  $('.contact-close').click(function () {
    $('.popup-contact-wrapper').fadeToggle("slow", "linear");
  });

  $('.popup-contact-icon-wrapper img').click(function () {
    $('.popup-contact-wrapper').fadeToggle("slow", "linear");
  })

  $("#contact-form").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var url = form.attr('action');

    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(),
      success: function (data) {
        var elForm = document.querySelector('.contact-form');
        elForm.style.display = 'none';

        var el = document.getElementById('contact-form-success');
        el.style.display = 'block';

        $('html, body').animate({
          scrollTop: $("#contact-wrapper").offset().top
        }, 2000);
      },
      error: function (xhr, textStatus, errorThrown) {
        var errors = JSON.parse(xhr.responseText);

        var el = document.getElementById('contact-form-errors');
        el.style.display = 'block';

        var error_container = '<ul>';
        for (var i = 0; i < errors.length; i++) {
          var list = errors[i];
          error_container += '<li>' + list + '</li>';
        }
        error_container += '</ul>';

        $('#contact-form-errors').html(error_container);
      }
    });
  });
});
